






import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';
import { StaticText, IFormField } from 'client-website-ts-library/types/Forms/FieldTypes';

import ErrorList from '../ErrorList.vue';

@Component({
  components: {
    ErrorList,
  },
})
export default class FormStaticTextField extends Vue implements IFormField {
  @Prop()
  private readonly def!: StaticText;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  private value: string | null = null;

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get elemId() {
    return `field_${this.def.FieldId}`;
  }

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: null,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setValue(value: FormValue): void {}
}
